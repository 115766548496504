<template>
  <div>
    <section id="ComunidadBanner " class="community mt-0">
      <img
        class="d-none d-md-block desk-img"
        :src="require(`../../assets/${dataES.backgroundImg}`)"
      />
      <div class="__content">
        <div class="__description">
          <div class="w-100 d-flex justify-content-start ms-8">
            <h2>
              <span style="color: #14993c"> Español </span>
            </h2>
          </div>
          <input
            class="form-control form-control-lg wet-form fw-bold text-center mb-2"
            type="text"
            v-model="dataES.title"
          />
          <textarea
            class="form-control form-control-lg wet-form texto mb-2"
            rows="5"
            type="text"
            v-model="dataES.description"
          />

          <div class="w-100 d-flex justify-content-start ms-8">
            <button
              class="btn btn-success btn-lg mt-3 mb-3"
              @click="syncContent('ES')"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="w-100">
      <hr class="pill my-5" />
    </div>

    <section id="ComunidadBanner" class="community mb-0">
      <img
        class="d-none d-md-block desk-img"
        :src="require(`../../assets/${dataES.backgroundImg}`)"
        alt="Imagen de banner de seccion de comunidad"
      />
      <img
        class="d-block d-md-none background-img"
        src="../../assets/img/comunidad-bakcground-mobile.webp"
        alt=""
      />
      <div class="__content">
        <div class="__description">
          <div class="w-100 d-flex justify-content-start ms-8">
            <h2>
              <span style="color: #14993c"> English </span>
            </h2>
          </div>
          <input
            class="form-control form-control-lg wet-form fw-bold text-center mb-2"
            type="text"
            v-model="dataEN.title"
          />
          <textarea
            class="form-control form-control-lg wet-form texto mb-2"
            rows="5"
            type="text"
            v-model="dataEN.description"
          />

          <div class="w-100 d-flex justify-content-start ms-8">
            <button
              class="btn btn-success btn-lg mt-3 mb-3"
              @click="syncContent('EN')"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getSection, putSection } from "../../helpers/contenido";

export default {
  name: "DiferenciadoresAdmin",
  data() {
    return {
      dataES: {
        title: "Diferenciadores",
        description:
          "Se combinan las características más diferenciadoras para provocar gran impacto en el futuro de las empresas.",
        backgroundImg: "img/Diferenciadores-es.webp",
        backgroundImgMobile: "img/Diferenciadores-mobile-es.webp",
      },

      dataEN: {
        title: "Diferenciadores",
        description:
          "Se combinan las características más diferenciadoras para provocar gran impacto en el futuro de las empresas.",
        backgroundImg: "img/Diferenciadores-es.webp",
        backgroundImgMobile: "img/Diferenciadores-mobile-es.webp",
      },
    };
  },
  methods: {
    async fetchContent() {
      const section = await getSection("differBanner");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "differBanner",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "differBanner",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchContent();
  },
};
</script>

<style scoped lang="scss">
.community {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 64px 0;

  /* h2 {
    font-size: 2.813rem !important;
    font-family: "GentiumBold" !important;
  } */

  /* p {
    font-size: 0.813rem !important;
    font-family: "InterRegular" !important;
  } */
  .__content {
    height: 100%;
    width: 100%;
    max-width: 1166px;
    position: absolute;
    padding: 30px 20px;
    left: 48%;
    //backdrop-filter: saturate(100%) blur(10px);
  }

  .background-img {
    height: 1250px;
    width: 100%;
  }

  .__description {
    h3 {
      margin: 25px 0 0 0;
    }
  }

  .inside-img {
    position: absolute;
    bottom: 20%;
    width: 100%;
    max-width: 375px;
  }
  .desk-img {
    //position: absolute;
    //z-index: -1;
    height: auto;

    width: 100%;
    //max-height: 892px;
    max-width: 1920px;
  }
}

@media (min-width: 982px) {
  .community {
    .__content {
      padding: 65px;
      backdrop-filter: none;

      .__description {
        max-width: 50%;
      }

      // p {
      //   font-size: 2rem !important;
      // }
    }
  }
}

hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}
</style>
